import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-show-spinner',
  templateUrl: './show-spinner.component.html',
  styleUrls: ['./show-spinner.component.css'],
})
export class ShowSpinnerComponent implements OnInit {
  showSpinner: boolean = true;
  constructor(private documeService: DocumentService) {
    this.documeService.getMessage().subscribe((res) => {
      this.showSpinner = res;
    })
  }

  ngOnInit(): void {}
}
