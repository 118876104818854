import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCookieInfoModel } from './Models/UserCookieInfoModel';
import { UserModel } from './Models/UserModel';
import { GlobalService } from './services/global.service';
import { UsersService } from './services/users.service';
declare var UIkit: any;
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  subscription: any;
  app_stage: number;
  currentLink;
  constructor(private globalService: GlobalService, private userService: UsersService, private cookieService: CookieService, private router: Router, private titleService: Title) {
    this.titleService.setTitle("Meraemi Document Upload")
  }

  userInfo: UserModel;
  isDesableComponent: boolean = true;

  ngOnInit() {

    // this.router.events.subscribe((router) => {
    //   this.currentLink = this.router.url
    //   console.log("%%%%%%%%%%%%%%%%%%%%%%%%%% this.currentLink%%%%%%%%%%%%%%%%%%%")
    //   console.log(this.currentLink)
      
    //   if (this.currentLink == "/not-authorized") {
    //     this.isDesableComponent = false;
    //   }
    // });
   
    //   var token = this.userService.getUserAbstractDetail().token; //Get token from user service getUserAbstractDetail function
    //   if (token != "") {
    //     this.subscription = this.userService.getUserDetailsByToken(token).subscribe( //Get user details from user service  by passing token to getUserDetailsByToken function
    //       (response: any) => {
    //         if (response['status']) {
    //           console.log('response');
    //           let user_profile = this.userService.getUserAbstractDetail().user_profile;
    //           this.appstageAndRedirect(user_profile);
    //           console.log('user_profile');
    //           console.log(this.app_stage);
    //           setTimeout(() => {
    //             if (this.app_stage <= 2) {
    //               this.router.navigate(['/not-authorized']);
    //             } else {
    //               let users = new UserModel(response);
    //               this.userInfo = users;
    //               this.userService.sendUserDetails(this.userInfo);
    //             }
    //           }, 2000);

    //         } else {
    //           this.router.navigate(['/not-authorized']);
    //         }
    //       },
    //       (err) => {
    //         console.log(err['error'].error);
    //         this.router.navigate(['/not-authorized']);
    //       }
    //     );
    //   } else {
    //     //if (this.globalService.ENVORMENT == "LOCAL") {
    //     //  this.router.navigate(['/document-list']);
    //     // }else{
    //     window.location.replace(this.globalService.fb_login_link);
    //     // }
    //   }
  

  }

  // appstageAndRedirect(userProfile) {
  //   this.userService.appStateCheckAndRedirect(userProfile).pipe(map(response => response['redirect_stage']['app_stage'])).subscribe((response) => {
  //     this.app_stage = parseInt(response);
  //   }, (err) => {
  //     this.app_stage = 0;
  //   });
  // }

}
