import { Component, Input, OnInit, HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserModel } from 'src/app/Models/UserModel';
import { UsersService } from 'src/app/services/users.service';
declare var UIkit: any;
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  // @Input() users: UserModel;
  userInformationStatus: boolean = false;
  userFirsName: string = '';
  userProfilePic: string;
  logoutModal: any;
  public screenWidth: any;
  public screenHeight: any;
  mobileView: boolean = false;
  documentTitle: string;
  constructor(private userService: UsersService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 600) {
      // console.log(this.screenWidth )
      this.mobileView = true;
    } else {
      this.mobileView = false;

    }

    this.userService.getDocumentTitle().subscribe((res) => {
      console.log(res)
      this.documentTitle = res;
    })

    this.userService.getUserDetails().subscribe((Response) => {


      this.userInformationStatus = true;
      this.userFirsName = Response.firstName;
      this.userProfilePic = Response.profilePic;


    });


  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 600) {
      this.mobileView = true;
    } else {
      this.mobileView = false;

    }
  }
  logoutSession() {
    //this.userService.logOut();
    var thiss = this;
    UIkit.modal.confirm('Are you sure want to Logout?').then(function () {
      thiss.userService.logOut();
    }, function () {
      console.log('Rejected.')
    });
  }
  f
}
