import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-document-response',
  templateUrl: './document-response.component.html',
  styleUrls: ['./document-response.component.css']
})
export class DocumentResponseComponent implements OnInit {

  userfirstName:string; 
  constructor(private userService: UsersService) { 
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "./assets/chatApp.js";     
    document.getElementsByTagName('head')[0].appendChild(script);

  }

  ngOnInit(): void {

    this.userService.sendDocumentTitle("Document Upload Completed");
    this.userService.getUserDetails().subscribe((response) => {
      console.log('response');
      console.log(response)
      this.userfirstName = response.firstName;
    });
   
  }

  

}
