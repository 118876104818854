export class UserModel {
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNo: string;
  profilePic: string;
  status: boolean;
  userId:number;

  constructor(userResponse: any) {
    this.firstName = userResponse['result'].first_name;
    this.lastName = userResponse['result'].last_name;
    this.emailAddress = userResponse['result'].email_address;
    this.mobileNo = userResponse['result'].mobile_number;
    this.profilePic = userResponse['result'].profile_pic;
    this.userId = userResponse['result'].user_id;
    this.status = userResponse.status;
  }
}
