
export class UserCookieInfoModel{
    loan_id?:number;
    lender_id?:number;
    profile_id?:number;
    product_id?:number;
    loan_code?:string;
    user_id?:number;
    doc_catg_id?:string;
    constructor(userCookieInfo:any){
        this.loan_id = userCookieInfo.loan_id;
        this.lender_id = userCookieInfo.lender_id;
        this.product_id = userCookieInfo.product_id;
        this.loan_code = userCookieInfo.loan_code;
        this.user_id = parseInt(userCookieInfo.user_id);
        this.doc_catg_id = userCookieInfo.doc_catg_id;

    }
}