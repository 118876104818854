import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
declare var UIkit: any;
declare var $: any;

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit {
  showSessionModal:boolean = false;
  progressInterval 
  constructor(public globalService:GlobalService,private cookieService: CookieService,private userService: UsersService) { }
  counterTime:number;

  ngOnInit(): void {
    this.showSessionModal = true;
   UIkit.modal("#session_timeout_modal").hide();
  
  //   this.modal.show();
   // $('#sessionTimeOutTimer').fadeIn('slow');          

    this.inactivityTime();
    this.counterTime=this.globalService.SESSION_REDIRECT_TO_LOGIN; 
    clearInterval(this.progressInterval);   
    this.doSessionExpire();
  }

  
  logoutSession(){
  
    var getprofileJSONString =this.cookieService.get('user_profile');
    var bytes  = CryptoJS.AES.decrypt(getprofileJSONString.toString(),environment.encryptionCokieKey); 
    var getprofileJSON = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    var user_profile = getprofileJSON;
    var user_id = user_profile['user_id'];
    console.log(user_id)
    this.userService.setTimeOutUser(user_id).subscribe((res)=>{     
      if(res['status']){
       window.location.href = environment.logout;
      
      }
    });
 
   }
 
   doSessionExpire(){    
     setTimeout(()=>{    
      UIkit.modal("#session_timeout_modal").show();
           setTimeout(()=>{
             $('#sessionTimeOutTimer').fadeIn('slow');          
             this.progressInterval =setInterval(()=>{
               this.counterTime --;
                 if(this.counterTime==0){                  
                  // this.logoutSession();
                  $("#sessionContent").text('').html('Your session has been expired.');                
                  $('#continueBtn').attr('disabled',true);
                  $("#sessionTimeOutTimer").hide();
                  clearInterval(this.progressInterval);
                 }
             },1000)
           },this.globalService.SESSION_REDIRECT_ICON_DISPLAY_TIME_OUT);
      },this.globalService.SESSION_TIME_OUT);
      
      
    }
    continueSession(){
     
      clearInterval(this.progressInterval);
      this.counterTime = 60;
      //$('#sessionTimeOutTimer').fadeOut('slow');    
      UIkit.modal("#session_timeout_modal").hide();
 
      setTimeout(()=>{
       this.doSessionExpire();
        },this.globalService.SESSION_TIME_OUT);
    }
 
    ngOnDestroy() {
     if (this.progressInterval ) {
       clearInterval(this.progressInterval);
     }
   }
 
 
   inactivityTime() {
     var time;    
     var settimeout = false;
     var thiss = this;
     window.onload = resetTimer;    
     document.onmousemove = resetTimer;
     document.onkeypress = resetTimer;
     function resetTimer() {
         clearTimeout(time);
   
         time = setTimeout(()=>{
           if(settimeout == false){ 

            UIkit.modal("#session_timeout_modal").show();
             $("#sessionContent").text('').html("Oops! You've stayed idle too long. Please re-login to continue where you left off.");                
             $('#continueBtn').attr('disabled',true);
             $("#sessionTimeOutTimer").hide();
             settimeout= true;
           }
         
         }, thiss.globalService.IDLE_TIME_OUT)
       }
     }
}
