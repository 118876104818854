import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserCookieInfoModel } from '../Models/UserCookieInfoModel';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UsersService {


  userInfoCookie: UserCookieInfoModel;
  user_profile: String;
  token: string;
  constructor(
    private http: HttpClient,
    private cookiservice: CookieService,
    private router: Router
  ) { }

  getUserAbstractDetail() {
    //For loanTap
    if (environment.env == 'LOCAL') {
      //var token =null;
      this.token = 'A0HHJ81ENmy6hI3Ec/zlflkiEV8H2/Vx/NmEWMaMvdkybsvyp7HfqmXsraHXQ4DLAyqUpxnNKXaB1Yz+y9UiOlRJHaV7vjGPPW/HyEL7nZWYsEyI1lrcnpuv6C25gBy8MiuRWb1YG8+RtgO5MT/6TA==';
      //this.token = null;
      // var ciphertext = CryptoJS.AES.encrypt(
      //   JSON.stringify({
      //     loan_id: 1,
      //     lender_id: 43,
      //     profile_id: 1,
      //     product_id: 4,
      //     loan_code: 'PL25214964',
      //     user_id: 4,
      //     doc_catg_id: '0',
      //   }),
      //   this.glblserrvice.encryptionCokieKey
      // );
      this.cookiservice.set(
        'token',
        this.token,
        {
          path: '/',
          domain:environment.domain
        }


      );

      //this.cookiservice.set("user_profile", ciphertext);
      // this.cookiservice.set("user_profile", ciphertext.toString(),
      //   {
      //     path: '/',
      //     domain: this.glblserrvice.domain
      //   });

      //get user_profile from cookie 
      this.user_profile = this.cookiservice.get('user_profile');
      if ((this.user_profile != "" && this.token != "")) {
        var bytes = CryptoJS.AES.decrypt(
          this.user_profile.toString(),
          environment.encryptionCokieKey
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.userInfoCookie = new UserCookieInfoModel(decryptedData);
      }

      let cookieData = {
        'token': this.token,
        'user_profile': this.userInfoCookie
      }
      return cookieData;
    } else {

      this.token = this.cookiservice.get('token');


      this.user_profile = this.cookiservice.get('user_profile');

      //decrypt user_profile from cookie 
      if ((this.user_profile != "" && this.token != "")) {
        var bytes = CryptoJS.AES.decrypt(
          this.user_profile.toString(),         
          environment.encryptionCokieKey
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.userInfoCookie = new UserCookieInfoModel(decryptedData);
      }

      let cookieData = {
        'token': this.token,
        'user_profile': this.userInfoCookie
      }
      return cookieData;
    }
  }
  //Call users/details to get users details by passing token from db
  getUserDetailsByToken(token): any {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', environment.bearer_token)
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(`${environment.url}/users/details`, JSON.stringify({ data: { results: [{ token_key: token }] } }), { headers: headers });
  }
  getUserDetailsByUserId(userId: string): any {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', environment.bearer_token)
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(`${environment.url}/users/details`, JSON.stringify({ data: { results: [{ user_id: userId }] } }), { headers: headers });
  }

  setTimeOutUser(user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', environment.bearer_token).set('Access-Control-Allow-Origin', '*');
    return this.http.post(`${environment.url}/users/sessionTimeOut`, JSON.stringify({ "data": { "results": { "user_id": user_id } } }), { headers: headers });
  }

  logOut() {
    console.log('logout')
    this.cookiservice.delete('token');
    this.cookiservice.delete('user_profile');
    //this.router.navigate(['/document-list']);
    window.location.replace(environment.logout);

  }

  appStateCheckAndRedirect(decryptedData) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", environment.bearer_token);
    return this.http.post<any>(
      `${environment.url}/master/PL/iap/appStateCheckAndRedirect`,
      JSON.stringify({
        data: {
          results: decryptedData,
        },
      }),
      { headers: headers }
    );
  }
  getUsersLoanDetails(user_id: number) {
    let userRequest = { "data": { "results": [{ "user_id": user_id }] } };
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', environment.bearer_token)
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`${environment.url}/loans/users`, userRequest, { headers: headers });
  }
  private subject = new Subject<any>();
  private titleSubject = new BehaviorSubject<string>("Select");

  sendUserDetails(message: any) {
    this.subject.next(message);
  }

  clearUserDetails() {
    this.subject.next();
  }

  getUserDetails(): Observable<any> {

    return this.subject.asObservable();
  }


  sendDocumentTitle(message: string) {
    this.titleSubject.next(message);
  }

  clearDocumentTitle() {
    this.titleSubject.unsubscribe();
  }

  getDocumentTitle(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  setUserProfileDataByUserId(userId) {
    this.getUsersLoanDetails(userId).subscribe((response) => {
      console.log('Loan Details response');
      if (response['status'] == true) {
        let loanDetails = response['data'][0]['loans'][0]['loanDetails'];
        var loanId = parseInt(loanDetails.loan_id);
        var lender_Id = parseInt(loanDetails.lender_id);
        var jsonUser = JSON.stringify({
          loan_id: loanId,
          lender_id: lender_Id,
          profile_id: 1,
          product_id: 4,
          loan_code: loanDetails.loan_code,
          user_id: userId,
          doc_catg_id: '0',
        });
        console.log('jsonUser');
        console.log(jsonUser);
        var ciphertext = CryptoJS.AES.encrypt(jsonUser.toString(), environment.encryptionCokieKey);
        this.cookiservice.set("user_profile", ciphertext.toString(), {
          path: '/',
          domain: environment.domain
        });
      } else {
        // console.log("Not a valida user");
        this.router.navigate(['/not-authorized']);
      }
    }, (err) => {
      console.log(err);
    });

  }



  setUseriapConsent(consentId, userId, consentAction) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", environment.bearer_token)
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post(
      `${environment.url}/consent/action/add`,
      JSON.stringify({
        data: {
          results: {
            consent_id: consentId,
            consent_action: consentAction,
            consent_by_type: "USER",
            consent_by_id: userId,
            change_consent_action_id: "0",
            consent_source: "1",
          },
        },
      }),
      { headers: headers }
    );
  }

}
