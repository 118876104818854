import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {



  SESSION_TIME_OUT: number;
  SESSION_REDIRECT_ICON_DISPLAY_TIME_OUT: number;
  SESSION_REDIRECT_TO_LOGIN: number;
  IDLE_TIME_OUT: number;

  DOCUMENT_CONSENT: number;
  idfcLenderId: number;
  fullertonLenderId: number;
  loantapLenderId: number;
  offlineLenderId: number;







  constructor() {


    //live
    this.SESSION_TIME_OUT = 1500000; //25 Minuite

    this.SESSION_REDIRECT_ICON_DISPLAY_TIME_OUT = 1500;
    this.SESSION_REDIRECT_TO_LOGIN = 60;
    this.IDLE_TIME_OUT = 600000; //10 Minutes
    //this.IDLE_TIME_OUT = 600000; //10 Minutes
    this.DOCUMENT_CONSENT = 10;

    this.idfcLenderId = 53;
    this.fullertonLenderId = 22;
    this.loantapLenderId = 43;
    this.offlineLenderId = 1;

  }
}
