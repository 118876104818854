import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DocumentService } from '../../services/document.service';
import { UsersService } from '../../services/users.service';

import { UserCookieInfoModel } from 'src/app/Models/UserCookieInfoModel';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
declare var UIkit: any;

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  list: any = [];
  selectedArray: any = [];
  neewObject: any = {};
  doc_id: any;
  residenceAddress: string = '';
  selected = true;
  selectLanguage: boolean = false;
  list$: any;
  data$: any;
  newarray: number | boolean;
  allCatg: boolean;
  checkDocumentsConfirm: boolean;
  user_profile: UserCookieInfoModel;
  userfirstName: string;
  validationErrorMessage: string = "";
  showMoreLess: boolean = false;
  moreLessText: string = "More";
  userId: number;
  @ViewChild('docUploadBtn') docUploadBtn;
  @ViewChild('consentDocUploadTextRef') consentDocUploadTextRef;
  constructor(
    private documentService: DocumentService,
    private userService: UsersService,
    private router: Router,
    private titleService: Title,
    private globalService:GlobalService
  ) {
    this.titleService.setTitle("Document List | Document Upload");
  }

  ngOnInit() {
    this.userService.sendDocumentTitle("Select Document");
    this.userService.getUserDetails().subscribe((response) => {
      console.log("AAAAAAAAAAAAAA")
      console.log(response)
      if (response['status']) {
        this.userfirstName = response.firstName;
        this.userId = response.userId;
      }
    });
    this.user_profile = this.userService.getUserAbstractDetail().user_profile; //Get user_profile from user service getUserAbstractDetail function

    this.documentService.getDynamicDocumentList(this.user_profile).subscribe(
      (response) => {
        console.log(response)
        if (response['status']) {
          this.list = response['result'];
          // load aadhar and pan first in list
          this.list.sort((a, b) => {
            return a.cat_id > b.cat_id ? 1 : -1;
          });
          // this.list.sort((a, b) => {
          //   return a.cat_id == 4 ? 1 : -1;
          // });
          for (let i = 0; i < response['result'].length; i++) {
            const docCatList = response['result'][i];
            if (docCatList['cat_id'] == 2 || docCatList['cat_id'] == 1) {
              for (let j = 0; j < docCatList['document_list'].length; j++) {
                const docList = docCatList['document_list'][j];
                this.doc_id = docList['doc_id'];
              }
              this.neewObject =
              {
                "catgId": docCatList['cat_id'],
                "userId": this.user_profile.user_id,
                "questId": 1,
                "docId": this.doc_id
              };
              this.selectedArray.push(this.neewObject);
            }

          }
        }
      },
      (err) => {
        //console.log(err);
        UIkit.notification({ message: "Opps!!! Its not you but us and we are experiencing some network issues. You may try after sometime and connect again", status: 'danger', pos: 'bottom-center' });

      }
    );
    this.userService.getUsersLoanDetails(this.user_profile.user_id).subscribe(
      (response) => {
        if (response['status']) {
          for (let i = 0; i < response['data'].length; i++) {
            const residenceaddress = response['data'][i]['loans'][0]['resaddressDetails'];
            if (residenceaddress['address_1'] != undefined || residenceaddress['address_2'] != undefined) {
              this.residenceAddress = residenceaddress['address_1'] + ' ' + ' ' + residenceaddress['address_2'];

            }
          }
        }
      }
    );
  }

  onOptionsSelected(doc_id, catg_id) {
    this.neewObject =
    {
      "catgId": catg_id,
      "userId": this.user_profile.user_id,
      "questId": 1,
      "docId": doc_id
    };
    this.selectedArray.push(this.neewObject);

  }

  setSelectedDocumentList() {

    if (this.selectLanguage) {
      console.log("selected");
      if (this.selectedArray.length == 5) {
        // this.insertSelectedDocToDb();

        this.validationErrorMessage = "";
        this.documentService.sendMessage(false);
        UIkit.modal("#modal-sections-doc-consent").show();
      } else {
        this.validationErrorMessage = "Select all document list to proceed continue..";
      }

    } else {
      console.log("NON selected");
      console.log(this.selectedArray.length);
      if (this.selectedArray.length >= 5) {
        this.selectedArray.splice(2, 1)
      }
      if (this.selectedArray.length < 4) {
        this.validationErrorMessage = "Select all document list to proceed continue..";
      } else {
        //this.insertSelectedDocToDb();
        this.validationErrorMessage = "";

        this.documentService.sendMessage(false);
        UIkit.modal("#modal-sections-doc-consent").show();
      }
    }

  }

  insertSelectedDocToDb() {
    this.validationErrorMessage = "";
    this.documentService.setSelectedDocumentList(this.selectedArray).subscribe(
      (response) => {
        console.log(response)
        if (response['status']) {
          //this.consentDocUploadTextRef.nativeElement.style.display = 'none';
          //this.documentService.sendMessage(false);
          //this.docUploadBtn.nativeElement.disabled = true;

          UIkit.modal("#modal-sections-doc-consent").hide();
          this.router.navigate(['/upload-document']);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  changeRadioAddress(event) {
    let addressConfirm = event.target.value;
    if (addressConfirm == 0) {
      this.selectLanguage = true;

    } else {
      this.selectLanguage = false;

    }
  }
  showControls(id) {

    if (id == 1 || id == 2 || (id == 4 && this.selectLanguage)) {
      return false;

    } else if ((id != 4) || this.selectLanguage) {
      return false;
    } else {
      return true;

    }
  }
  onDocumentCheckboxChecked(checked) {
    console.log(checked)
    if (checked) {
      this.checkDocumentsConfirm = true;

    } else {
      this.checkDocumentsConfirm = false;

    }
  }



  addUserConsent(userId: number, optinId: number, constentValue: string) {

    this.userService
      .setUseriapConsent(
        optinId,
        userId,
        constentValue
      )
      .subscribe(
        (response) => {        
          if (response["status"]) {
            this.insertSelectedDocToDb();
          }
        },
        (err) => {
          console.log(err);
        }
      );

  }

  showMoreText() {
    if (this.showMoreLess) {
      this.showMoreLess = false;
      this.moreLessText = "More";
    } else {
      this.showMoreLess = true;
      this.moreLessText = "Less";
    }
  }

  docUploadConsentContinue() {
    this.consentDocUploadTextRef.nativeElement.style.display = 'none';
    this.documentService.sendMessage(true);
    this.docUploadBtn.nativeElement.disabled = true;
    this.addUserConsent(this.userId, this.globalService.DOCUMENT_CONSENT, "Accepted");
  }




}