import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css']
})
export class NotAuthorizedComponent implements OnInit {

  notAuthorizedContent: string;
  constructor(private globalService:GlobalService ) { }

  ngOnInit(): void {
    this.notAuthorizedContent = "Opps!!! Its not you but us and we are experiencing some network issues. You may try after sometime and connect again";

  }

  redirectToHome(){
    window.location.href = environment.siteUrl;
  }

}
