<!-- login-section container start -->

<div class="uk-cover-container" id="login-section">

    <div class="uk-container uk-container-large ">
        <div class="uk-column-1-1">
            <div id="meraemi-logo" class="uk-padding-small">
                <div class="uk-align-left mobileViewLogo" [hidden]="!mobileView">
                    <img src="../assets/images/Logo.png">
                </div>
                <div class="uk-align-left " [hidden]="mobileView">
                    <img src="../assets/images/Logo.svg">

                </div>
                <div class="uk-align-right profile-section" *ngIf="this.userInformationStatus; else elseBlockProfilePic">
                    <img src="../assets/images/profile.png">
                    <div class="logout-section uk-align-right">
                        <a (click)="logoutSession()">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 logoutIcon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                        </a>

                    </div>
                </div>

                <ng-template #elseBlockProfilePic>
                    <div class="uk-align-right profile-section">
                        <app-show-spinner></app-show-spinner>
                    </div>
                </ng-template>

            </div>
        </div>
        <div class="uk-column-1-1 clear-both" id="slide-user-info" *ngIf="this.userInformationStatus; else elseBlockUserInfo">
            <h3>{{documentTitle}}</h3>
        </div>

        <ng-template #elseBlockUserInfo>
            <div class="uk-column-1-1 clear-both" id="slide-user-info">
                <app-show-spinner></app-show-spinner>
            </div>
        </ng-template>
    </div>
    <!-- login-section container start -->

    <div id="top-border-curve"></div>