import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentDetailsComponent } from './Iap/document-details/document-details.component';
import { UploadDocumentComponent } from './Iap/upload-document/upload-document.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DocumentListComponent } from './iap/document-list/document-list.component';
import { AuthGuard } from './guard/auth.guard';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { DocumentResponseComponent } from './Iap/document-response/document-response.component';


const routes: Routes = [
  { path: 'not-authorized', component: NotAuthorizedComponent, canActivate: [AuthGuard] },
  { path: 'document-details', component: DocumentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'upload-document', component: UploadDocumentComponent, canActivate: [AuthGuard] },
  {
    path: 'document-list', component: DocumentListComponent, canActivate: [AuthGuard]
  },
  {
    path: 'document-response', component: DocumentResponseComponent , canActivate: [AuthGuard]
  },
  { path: 'uservalidates', loadChildren: () => import('./uservalidate/uservalidate.module').then(m => m.UservalidateModule), canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
