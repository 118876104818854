<!-- document-section container start -->
<app-session-timeout></app-session-timeout>
<div class="uk-cover-container">
    <div id="mainUploadSection">
        <h3>{{slideTitle}}</h3>
        <app-show-spinner></app-show-spinner>
        <div class="uk-container uk-container-small uploadsection-back-color">
            <div id="user-salutation">
                <h2>Hi {{userfirstName}},</h2>
                <p>Please upload your selected documents.</p>
            </div>
            <div class="uk-flex-center uploadsection-back-color" uk-grid>
                <!-- document-upload-input-section start -->
                <div class="uk-width-1-2@m uk-flex-first" *ngFor="let docUpload of this.docUploadWizard;let i=index">
                    <div class="js-upload uk-placeholder uk-text-center">
                        <p class="sucessUploadedIcon uk-animation-scale-up " uk-icon="icon: check; ratio: 2" *ngIf="this['selectedIndex'+i] || docUpload.docUploadStatus"></p>
                        <p><span uk-icon="icon: cloud-upload;ratio: 4"></span></p>
                        <div uk-form-custom>
                            <input #myInput type="file" accept="image/*,application/pdf" [attr.disabled]="docUpload.docUploadStatus || this['selectedIndex'+i] ? '' : null" (change)="dynamicDocumentUpload(i,$event,docUpload.formId,docUpload.docListId,docUpload.inputName)">
                            <span class="uk-link upload-file-text">{{docUpload.inputLabel}}</span>
                        </div>

                    </div>

                    <div class="uk-margin" *ngIf="this['pdfPasswordDisplay_'+i]">
                        <input class="uk-input uk-form-width-medium" type="text" placeholder="Pdf Password" name="pdf_password" id="pdf_password" #pdf_password>
                        <button (click)="pdfPasswordSubmit()" class="uk-button uk-button-default uk-button-medium pdf_password_btn">submit</button>

                    </div>
                    <div class="progress" *ngIf="this['progressBarDisplay_'+i]">
                        <div class="progress-bar progress-bar-animated" [style.width.%]="this['uploadDocumentProgress_'+i]" role="progressbar"></div>
                    </div>
                    <span class="progress-percentage" *ngIf="this['progressBarDisplay_'+i]">{{this['uploadDocumentProgress_'+i]}}%</span>

                </div>
                <!-- <div class="uk-width-1-1@m uk-align-center uk-flex-center">
                    <p class="documentStatus" *ngIf="docUploadStatusText">
                        <img src="../../../assets/images/4V0b.gif"> {{docUploadText}}

                    </p>
                </div> -->
                <!-- document-upload-input-section end -->

                <!-- document-upload-instruction-section start -->
                <div class="uk-width-1-1@m uk-align-center uk-flex-center uk-margin-remove">
                    <div id="upload-instuction">
                        <h4>Instruction:</h4>
                        <ul id="add_to_me"></ul>
                    </div>
                </div>
                <!-- document-upload-instruction-section end -->
            </div>
        </div>
    </div>
</div>
<!-- document-section container end -->

<div class="uk-cover-container uploadsection-back-color uk-margin ">
    <div class="uk-container uk-container-xlarge clear-both doc-upload-button">
        <button type="button" [disabled]="!nextBtnDesabled" (click)="openModal()" class="btn-back">Submit    <div class="loader" *ngIf="loaderBtn"></div></button>
    </div>
</div>


<div [ngClass]="{'modale': true, 'opened': isOpenedCheck }" aria-hidden="true">
    <div class="modal-dialog">
        <!-- <div class="modal-header loaderImg">
            <img src="../../../assets/images/untitled-5.gif">
        </div> -->
        <div class="modal-body">
            <span class="loading">{{docUploadText}}</span>
        </div>

    </div>
</div>