import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  userdatasubject = new Subject<any>();

  constructor(
    private http: HttpClient
  ) { }

  sendMessage(data) {
    this.userdatasubject.next(data);
  }

  clearMessage() {
    this.userdatasubject.next();
  }

  getMessage(): Observable<any> {
    return this.userdatasubject.asObservable();
  }

  //Call /v1/application/getDocumentJsonResponse api to get dynamic document json response
  getDynamicDocumentResponse(data) {
    return this.http.post(
      `${environment.url_form}/v1/application/getDocumentJsonResponse`,
      JSON.stringify({
        request: {
          lender_id: data.lender_id,
          product_id: data.product_id,
          form_type: 'DOC_UPLOAD',
          source_type_id: 1,
          appl_id: 1,
        },
      })
    );
  }

  //Call v1/document/uploadFileapi to upload document to server
  uploadDocumentToServer(formData: any) {
    let headersData = new HttpHeaders()
      //set('Content-Type', 'multipart/form-data')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.post(`${environment.url_drive}/file/uploadFile`, formData, { headers: headersData });
  }


  getDynamicDocumentList(data) {
    console.log(" data['lender_id']");
    console.log(data['lender_id']);
    let headersData = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('Access-Control-Allow-Origin', "*");
    return this.http.post(
      `${environment.url_form}/v1/application/getDocumentListByCategory`,
      JSON.stringify({
        request: {
          lender_id: data['lender_id'],
          product_id: 4,
          form_type_id: 3,
          source_type_id: 1,
          appl_id: 1
        }
      })
    );
  }
  setSelectedDocumentList(data) {

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.post(`${environment.url_form}/v1/application/setSelectedDocumentList`, { "selectedDocument": data }, { headers: headers });


  }
  getresponseForAllDocument(data) {

    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.post(`${environment.url_form}/v1/application/getresponseForAllDocument`, data, { headers: headers });



  }
  getselectedDocumentList(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.post(`${environment.url_form}/v1/application/getselectedDocumentList`, JSON.stringify({
      request: {
        userId: data.user_id
      }
    }), { headers: headers });
  }


  updateAdharCardId(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.post(`${environment.url_form}/v1/application/updateUploadAadharId`, data, { headers: headers });
  }

  checkAllDocumentUploadStatus(userId:number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', "*");
    return this.http.get(`${environment.url_form}/v1/document/checkAllDocUPloadstatus/${userId}`, { headers: headers });
  }


}
