import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserModel } from '../Models/UserModel';
import { DocumentService } from '../services/document.service';

import { GlobalService } from '../services/global.service';
import { UsersService } from '../services/users.service';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user_profile: any;
  userInfo: UserModel;
  token: string;
  checkCurrentPageAndRedirect: string;
  constructor(private router: Router, private cookieService: CookieService, private documentservice: DocumentService, private userService: UsersService, private globalService: GlobalService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    console.log("route");
    this.checkCurrentPageAndRedirect = route['routeConfig']['path'];
    console.log('this.checkCurrentPageAndRedirect');
    console.log(this.checkCurrentPageAndRedirect);
    this.checkAuthorization();
    return true;
  }

  checkAuthorization() {
    if (this.checkCurrentPageAndRedirect != "uservalidates") {
      this.token = this.cookieService.get("token");
      console.log('this.token');
      console.log(this.token)
      if (this.token != "" && this.token != undefined) {
        this.user_profile = this.userService.getUserAbstractDetail().user_profile;
        this.userService.getUserDetailsByToken(this.token).subscribe((response) => {
          console.log("AUHT GUARD");
          console.log(response);
          if (response['status']) {
            let users = new UserModel(response);
            this.userInfo = users;
            this.userService.sendUserDetails(this.userInfo);
            this.userService.setUserProfileDataByUserId(users.userId);
            this.appStageAndRedirectedTo(this.user_profile,users.userId);
           



          } else {
            // this.userService.sendUserDetails("NotAuthorizedUser");
            this.router.navigate(['/not-authorized']);
          }

        }, (err) => {
          this.router.navigate(['/not-authorized']);
        });
      } else {
        // if (this.globalService.ENVORMENT == "LOCAL") {
        // this.router.navigate(['/document-list']);
        // } else {
        window.location.replace(environment.fb_login_link);
        // }
      }
    }
  }

  checkUserListExistOrNotByUserId(userId) {
    let data = {
      "user_id": userId
    };
    this.documentservice.getselectedDocumentList(data).subscribe((response) => {
      console.log("Aut Guard response");
      console.log(response);
      if (response['status'] == true) {
        if (this.checkCurrentPageAndRedirect == "document-details") {
          return true;
        } else {
         this.router.navigate(['/upload-document']);
         // this.router.navigate(['/document-response']);
        }
      } else {
        this.router.navigate(['/document-list']);
        
      }
    }, (err) => {
      console.log(err);
    })

  }


  appStageAndRedirectedTo(userDecryptData,userId) {
    this.userService.appStateCheckAndRedirect(userDecryptData).subscribe((res) => {
      console.log("APP STAGE REDIRECT");
      console.log(res);
      if(res['status']){
        let appStage =parseInt(res['redirect_stage']['app_stage']);
        
        if(appStage!=3 && appStage!=4){                
          this.router.navigate(['/not-authorized']);
        }else if(appStage==3){
          this.checkUserListExistOrNotByUserId(userId);
        }else if(appStage==4){
          this.router.navigate(['/document-response']);
         // window.location.href= environment.final_response_page;
         // window.stop();

        
        }
      }else{
        this.userService.clearUserDetails();
        this.router.navigate(['/not-authorized']);
      }
    }, (err) => {
      console.log(err);
    })
  }






}
