<div class="uk-cover-container">
    <div class="uk-container uk-container-large ">

        <div class="docUploadCompleted-section">
            <h4> Hello {{this.userfirstName}},</h4>

            <p>You have successfully uploaded your documents.<br/> <br/>for any further enquiries,please click below on "Start Chat" to drop us message and stay in touch using your WhatsApp account!</p>
            <p uk-margin class="uk-align-center">


                <!-- <button class="uk-button uk-button-secondary uk-align-center start-chat-btn"><img src="../../../assets/images/whatsapp.png">Start Chat</button> -->




            </p>
        </div>
    </div>
</div>