<div class="uk-cover-container">
    <div class="uk-container uk-container-large ">

        <div class="mainListSection">
            <!-- <div class="uk-flex uk-flex-wrap uk-flex-wrap-around uk-margin-bottom">
              
                <h2>Select Document</h2>
                <br>
                <h4>Please select the documents for upload from given options.</h4>
                <br>
                <h5><span>*</span>Mandatory documents are preselected</h5>               
            </div> -->
            <div class="mainListSection">
                <div class="uk-flex uk-flex-wrap uk-flex-wrap-around documentTitle">

                    <h2 style="font-size: 23px;">Hi {{this.userfirstName}},</h2>
                    <p>Please ensure you have the following documents ready for upload</p><br/>
                    <p><span>*</span>Mandatory documents are preselected</p>
                    <!-- <ul style="padding:0 0 0 18px;margin:5px 0 5px 0">
                        <li>Please ensure you have the following documents ready for upload</li>
                        <li>PNG, PDF, JPG, JPEG formats only</li>
                        <li>Max File size 5MB</li>
                        <li><span>*</span>Mandatory documents are preselected</li>
                    </ul> -->


                    <!-- <h4 style="color:white;font-size: .875rem;">HI,{{this.userfirstName}} Before Proceeding please ensure you have the following documents ready. Please upload only in PDF, JPG, JPEG or PNG fornat. Please ensure file upload size is less than 5MB.</h4> -->
                </div>



                <div class="uk-flex uk-flex-wrap uk-flex-wrap-around uk-margin-top">
                    <div *ngFor="let listing of list">

                        <div class="uk-margin dropdown-div" [hidden]="showControls(listing.cat_id )">
                            <dl class="uk-description-list uk-description-list-divider">
                                <dt> {{listing.cat_name}}<span *ngIf="(listing.cat_id == 2  || listing.cat_id == 1 )? true: false">*</span> <span *ngIf="(listing.cat_id!= 2  && listing.cat_id!= 1 )? true: false">#</span></dt>
                                <!-- <dd *ngIf="listing.cat_id != 2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</dd> -->

                            </dl>
                            <div uk-form-custom="target: > * > span:first-child">
                                <select #divs name="{{listing.cat_name}}" [id]="'cat_'+listing.cat_id" (change)='onOptionsSelected(divs.value,listing.cat_id);' [disabled]='(listing.cat_id == 2 || listing.cat_id == 1) ? true: false'>
                                        <option disabled selected>Select</option>
                                        <option *ngFor="let doclist of listing.document_list"  [value]="doclist.doc_id"  [selected]="(listing.cat_id == 2  || listing.cat_id == 1 )? true: false">{{doclist.dscription}}</option>
                        
                                     </select>
                                <button [ngClass]="(listing.cat_id == 2  || listing.cat_id == 1 )? 'disabledDropDown': 'enabledDropDown'" class="uk-button uk-button-default select-button" type="button" tabindex="-1">
                                 <span></span>
                                        <span [ngClass] ="(listing.cat_id == 2  || listing.cat_id == 1 )? 'hide': 'show'" uk-icon="icon: chevron-down"></span>
                               </button>

                            </div>
                            <dd *ngIf="listing.cat_id == 2 " class="userAddress">Address: {{this.residenceAddress}}</dd>
                            <dd *ngIf="listing.cat_id == 7 " class="userBankLov">* Latest 6 months statement reflecting salary credits</dd>

                            <dd style="color: white;font-size: 1rem;margin-top: 1rem;" *ngIf="listing.cat_id == 2 ">
                                <div class="uk-form-label">Does your document address match your Application ?</div>

                                <div class="uk-margin uk-grid-large uk-child-width-auto uk-grid">

                                    <label><input class="uk-radio" type="radio" name="radio2" value="1" (change)="changeRadioAddress($event);" checked> Yes</label>
                                    <label><input class="uk-radio" type="radio" name="radio2" value="0" (change)="changeRadioAddress($event);"> No</label>
                                </div>


                            </dd>

                        </div>
                    </div>
                </div>

            </div>
            <p class="formValidationError">{{validationErrorMessage}}</p>
            <div class="uk-container uk-container-large consentcheck">
                <label><input class="uk-checkbox" type="checkbox" #docConfirmCheckbox (change)="onDocumentCheckboxChecked(docConfirmCheckbox.checked)" /> I have the selected documents ready for upload.</label>
            </div>
            <div id="bottomListSection">
                <div class="uk-container uk-container-small">
                    <div class="uk-grid-medium uk-grid-margin uk-child-width-expand@s uk-text-center" uk-grid>
                        <div>
                            <div class="uk-margin">
                                <button class="uk-button uk-button-primary continue-button" [disabled]="!checkDocumentsConfirm" (click)="setSelectedDocumentList();">Continue <span uk-icon="icon:   arrow-right"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="modal-sections-doc-consent" uk-modal>
        <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
                <h3 class="uk-modal-title">Consent & Disclaimer</h3>
            </div>
            <div class="uk-modal-body">
                <app-show-spinner></app-show-spinner>
                <p #consentDocUploadTextRef> By uploading your document, you give explicit authorization to MeraEMI and its representatives to share your document and details thereof to its Associates,
                    <span *ngIf="showMoreLess">Affiliates, Lenders on its Platform. Further by uploading you indemnify MeraEMI
                    and its representatives to the authenticity of the document uploaded and agree that in the event of any data or documents being lost, deleted or leaked due to virus or malware attacks you will hold MeraEMI and its officials harmless
                    against any financial loss or damages. MeraEMI Platform Terms & Conditions apply.</span><span class="showMoreAnc" (click)="showMoreText()">{{moreLessText}}>></span></p>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
                <button class="uk-button uk-button-primary" #docUploadBtn (click)="docUploadConsentContinue()" type="button">Continue</button>
            </div>
        </div>
    </div>