import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {  Router } from '@angular/router';

import { map } from 'rxjs/operators';
import { UserCookieInfoModel } from 'src/app/Models/UserCookieInfoModel';
import { UserModel } from 'src/app/Models/UserModel';
import { DocumentService } from 'src/app/services/document.service';
import { UsersService } from 'src/app/services/users.service';
declare var UIkit: any;
@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css'],
})
export class UploadDocumentComponent implements OnInit {
  docuUpload = [];
  docuUploadTemp = [];
  docUploadInnerData = [];
  desableSpinner: boolean = true;
  docIdArray = [];
  isUploadedDocument: boolean;
  isUploadedOrNot0: boolean = false;
  isUploadedOrNot1: boolean = false;
  isUploadedOrNot2: boolean = false;
  isUploadedOrNot3: boolean = false;
  array = [];
  user_profile: UserCookieInfoModel;
  stasusDoc = [];
  modal: any;
  defaultChoice: string = "fullAdhar";
  docId: number;
  progressEnable: boolean = false;
  showDocUploadStatus: boolean = true;
  forReloadSetInterval;
  userfirstName: string;
  userModel: UserModel;
  slideErrorInfo:string;
  noDocListFound:boolean = false;
  constructor(
    private documentService: DocumentService,
    private router: Router,
    private userService: UsersService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Selected Document | MeraEmi Document Upload");


  }

  ngOnInit(): void {

    this.userService.getUserDetails().subscribe((response) => {
      this.userfirstName = response.firstName;
      console.log(response.firstName);


    }, (err) => {
      console.log(err);
    });

    this.userService.sendDocumentTitle("Upload Document");
    this.user_profile = this.userService.getUserAbstractDetail().user_profile; //Get user_profile from user service getUserAbstractDetail function
    this.documentService.getselectedDocumentList(this.user_profile).subscribe(
      (res) => {
        if (res) {
          console.log(res);
          if (res['status']!=false) {
            const slide_innner_controls = res['result'];
            for (let i = 0; i < slide_innner_controls.length; i++) {
              // console.log(slide_innner_controls[i]['uploaded_status']);
              this.docuUpload.push({
                docId: slide_innner_controls[i]['doc_id'],
                docTitle: slide_innner_controls[i]['dscription'],
                stepId: this.isUploadedDocument,
                docInfo: 'This is mandatory document',
                isThisUploaded: 3
              });
              this.docuUploadTemp.push({
                docId: slide_innner_controls[i]['doc_id'],
                docTitle: slide_innner_controls[i]['dscription'],
                stepId: this.isUploadedDocument,
                docInfo: 'This is mandatory document',
                isThisUploaded: this.showDocUploadLoader(slide_innner_controls[i]['uploaded_status'])
              })
              this.docIdArray.push({ ['selected_doc_' + i]: slide_innner_controls[i]['doc_id'] });
              this.desableSpinner = false;
              this.documentService.sendMessage(this.desableSpinner);


            }
          } else {
            this.noDocListFound = true;
            this.slideErrorInfo = "We are not fetching user selected doc list...please select document from document list to upload document.";
            this.desableSpinner = false;
            this.documentService.sendMessage(this.desableSpinner);

          }
        }
        setTimeout(() => {
          this.docuUpload = [];
          this.docuUpload = JSON.parse(JSON.stringify(this.docuUploadTemp));
        }, 2000)
      }
    );

  }



  getDocUploadStatus(docId): boolean {
    for (let index = 0; index < this.stasusDoc.length; index++) {
      const sinelElement = this.stasusDoc[index];
      if (docId == Object.keys(sinelElement)[0]) {
        if (Object.values(sinelElement)[0] == 1) {
          return true;
        } else {
          return false;
        }
      }


    }


  }

  showDocUploadLoader(uploadedStatus) {
    //3 loader
    //2 submit pending
    //1 pending
    if (uploadedStatus == true) {
      return 2;
    } else if (uploadedStatus == false) {
      return 1;
    }

  }



  uploadDocumentPageRedirect(indexValue, isUploaded) {
    // console.log("isUploaded " + isUploaded)
    if (isUploaded == 1 && (indexValue == 29 || indexValue == 39)) {
      this.modal = UIkit.modal("#modal-sections", { escClose: false, bgClose: false });
      this.modal.show();
    } else {
      if (isUploaded == 1) {
        if (!this['isUploadedOrNot' + indexValue]) {
          this.router.navigate(['/document-details'], {
            queryParams: { doc_id: indexValue },
          });
        }
      }
    }
  }
  adharSelectionRedirect() {
    // console.log(this.defaultChoice);
    if (this.defaultChoice == "fullAdhar") {
      this.updateAadharcardId(this.user_profile, 29);
      this.modal.hide();

      this.router.navigate(['/document-details'], {
        queryParams: { doc_id: 29 },
      });
    } else if (this.defaultChoice == "seperateAdhar") {
      this.updateAadharcardId(this.user_profile.user_id, 39);
      this.modal.hide();
      this.router.navigate(['/document-details'], {
        queryParams: { doc_id: 39 },
      });
    }
  }

  getDocResponseUploadStatus(getDocid: number) {
    const request = {
      'userId': this.user_profile.user_id,
      'docId': getDocid
    }
    let docName = getDocid;
    //Service call to get document upload status  
    this.documentService.getresponseForAllDocument(request).pipe(map((response) => {
      // console.log("response");
      // console.log(response);
      const slide_innner_controls = response['getDocObject']['selected_doc_1'];
      for (let j = 0; j < slide_innner_controls.length; j++) {
        const getInnerSlideElement = slide_innner_controls[j];
        // console.log("No add " + getInnerSlideElement.docUploadStatus);
        if (getInnerSlideElement.docUploadStatus) {
          return 1;
        } else {
          return 0;
        }
      }
    })).subscribe(res => {
      if (res == 1) {
        let docstatus = { [docName]: 1 };
        this.stasusDoc.push(docstatus);
      } else {
        let docstatus = { [docName]: 0 };
        this.stasusDoc.push(docstatus);
      }
    });
  }



  updateAadharcardId(user_id, doc_id) {
    const request = JSON.stringify({
      "request": {
        "user_id": user_id,
        "doc_id": doc_id
      }
    });
    this.documentService.updateAdharCardId(request).subscribe((res) => {
      return true;

    }, (err) => {
      return false;
    })
  }

}
