import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header/header.component';
import { DocumentDetailsComponent } from './Iap/document-details/document-details.component';
import { UploadDocumentComponent } from './Iap/upload-document/upload-document.component';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { ShowSpinnerComponent } from './components/show-spinner/show-spinner.component';
import { CookieService } from 'ngx-cookie-service';
import { DocumentListComponent } from './iap/document-list/document-list.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { AuthGuard } from './guard/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { DocumentResponseComponent } from './Iap/document-response/document-response.component';


@NgModule({
  declarations: [
    AppComponent,
    DocumentDetailsComponent,
    UploadDocumentComponent,
    PageNotFoundComponent,
    HeaderComponent,
    ShowSpinnerComponent,
    DocumentListComponent,
    SessionTimeoutComponent,
    NotAuthorizedComponent,
    DocumentResponseComponent  
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,   
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    CookieService 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
