export const environment = {
  production: false,
  env:"UAT",
  bearer_token: 'Bearer b2d67a33-9e97-4684-8340-cdd02b2ea042', //for UAT
  encryptionCokieKey:'MPFiOtRx30gKAPnXYbFtv9n8Z8AgymNL', //for local and UAT
  domain:'.meraemi.com',
  fb_login_link:"https://uat.meraemi.com/user/login?redirectTo=docUpload",
  final_response_page:"document-response",
  logout:"https://uat.meraemi.com/user/logout",
  siteUrl:"https://uat.meraemi.com/",
  url: 'https://api2.meraemi.com/uat/v1', //for UAT
  url_form: 'https://api2.meraemi.com/uat_form', //For uat
  url_drive: 'https://api2.meraemi.com/uat_drive/v1', //For uat
};
