<!-- document-upload-section start -->
<app-session-timeout></app-session-timeout>

<div class="uk-cover-container">
    <div id="mainUploadSection">
        <div class="uk-container uk-container-large uploadsection-back-color">
            <div class="uk-flex-center uploadsection-back-color">
                <div id="user-salutation">
                    <h2>Hi {{userfirstName}},</h2>
                    <p>Please upload your selected documents.</p>
                </div>
                <div class="uk-width-1-1@m align-center margin-top-0">
                    <app-show-spinner></app-show-spinner>
                    <p uk-margin>
                        <span *ngIf="noDocListFound" class="errorInfo">{{slideErrorInfo}}</span>
                        <br/>
                        <a *ngIf="noDocListFound" class="redirectToDocList" routerLink="/document-list">Back To Document selection List</a>
                        <ng-container *ngFor="let document of docuUpload;let i = index">
                            <button (click)="uploadDocumentPageRedirect(document.docId,document.isThisUploaded)" class="uk-button uk-button-default uk-button-large docUploadBtn" [disabled]="this['isUploadedOrNot'+i]">
                                <span class="docIcon">
                                    <img src="../../../assets/images/file-icon.png">
                                </span>
                                <span class="docTitle">{{document.docTitle}}</span>
                                <small class="uploadedTitle" *ngIf="document.isThisUploaded === 2">(Uploaded)</small>
                                <br/>
                                <!-- <p class="descDocument">{{document.docInfo}}</p> -->                             
                                <p class="descSuccessDocument" *ngIf="this['isUploadedOrNot'+i]">Document Uploaded Sucessfully</p>   
                                <ng-container  [ngSwitch]="true">                                
                                        <span  *ngSwitchCase="document.isThisUploaded === 1"  class="cloudeUploaded" >
                                            <svg  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 img" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                                                <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                                            </svg>                            
                                        </span>
                                        <span   *ngSwitchCase="document.isThisUploaded === 2" class="cloudeUploaded"   >
                                            <img class="img" src="../../../assets/images/cloud_uploaded.png">
                                            
                                        </span>
                                        <span   *ngSwitchCase="document.isThisUploaded === 3" class="cloudeUploaded"   >
                                            <img class="img" src="../../../assets/images/4V0b.gif">
                                            
                                        </span>
                               </ng-container>                              
                               
                      
                            </button>
                        </ng-container>

                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-sections" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">Upload Aadhar</h2>
        </div>
        <div class="uk-modal-body">
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label><input class="uk-radio" value="fullAdhar" type="radio"   [(ngModel)]="defaultChoice" name="radio2" checked> Upload Full Aadhar Card</label>
                <label><input class="uk-radio" value="seperateAdhar"  type="radio"    [(ngModel)]="defaultChoice" name="radio2"> Upload seperate Front and Back </label>
            </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
            <button class="uk-button uk-button-primary" (click)="adharSelectionRedirect()" type="button">Continue</button>
        </div>
    </div>
</div>
<!-- document-upload-section end -->