
<div id="modal-sections" id="session_timeout_modal"  uk-modal>
    <div class="uk-modal-dialog">
        <div class="uk-modal-body">
            <p id="sessionTimeOutTimer">{{counterTime}}</p>
            <span>
                <img src="../../../assets/images/expired.png" alt="session_timeout">
            </span>
            <p id="sessionContent" >Your session is about to expire. Select Continue to reset your session timer or you may lose all data entered so far.</p>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default" (click)="logoutSession()" type="button" id="logoutBtn" >Logout</button>
            <button class="uk-button uk-button-primary" (click)="continueSession()" id="continueBtn" type="button">Continue</button>
        </div>
    </div>
</div>