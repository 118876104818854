
import { Component, OnInit, ElementRef, ViewChild, Input, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';

import { interval, Subscription } from 'rxjs';


import { UserCookieInfoModel } from 'src/app/Models/UserCookieInfoModel';
import { DocumentService } from 'src/app/services/document.service';
import { UsersService } from 'src/app/services/users.service';

declare var UIkit: any;
declare var $: any;
@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.css'],
})
export class DocumentDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('pdf_password') pdf_password: ElementRef;
  @Input() usersCookieInfo: UserCookieInfoModel;
  formObj = [];
  formData = new FormData();
  isOpenedCheck: boolean = false;
  numbersubscription: Subscription;
  docUploadWizard: any = [];
  @ViewChild('myInput') myInputVariable: ElementRef;
  step_id: number;
  desableSpinner: boolean;
  slideTitle: string;
  documentExtensions = ['jpeg', 'png', 'pdf', 'jpg'];
  uploadDocumentProgress_1: number = 10;
  uploadDocumentProgress_2: number = 10;
  uploadDocumentProgress_3: number = 10;
  uploadDocumentProgress_4: number = 10;
  uploadDocumentProgress_5: number = 10;
  progressBarDisplay_0: boolean = false;
  progressBarDisplay_1: boolean = false;
  selectedIndex0: boolean = false;
  selectedIndex1: boolean = false;
  pdfPasswordDisplay_0: boolean = false;
  pdfPasswordDisplay_1: boolean = false;
  file: any;
  form_id: any;
  doc_name: any;
  selectedIndex: any;
  doc_id: any;
  user_profile: UserCookieInfoModel;
  nextBtnDesabled: boolean = true;

  loaderBtn: boolean = false;
  interValApi;
  desesionForRedirct: boolean = false;
  docUploadStatusText: boolean = false;
  docUploadText = "Uploading Document Please wait";
  userfirstName: string;
  docUploadStatusArray: boolean[] = [];
  allDocUploadStatus: boolean = false;
  userId: number;
  constructor(
    private documeService: DocumentService,
    private route: ActivatedRoute,
    private userService: UsersService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Document Upload Wizard | Meraemi Document Upload");
    this.route.queryParams.subscribe((params) => {
      this.doc_id = params['doc_id'];
    });
  }
  ngOnDestroy(): void {
    this.numbersubscription.unsubscribe();
    clearInterval(this.interValApi);
  }


  ngOnInit(): void {
    //Service call to get document response
    this.userService.sendDocumentTitle("Upload Document");
    this.userService.getUserDetails().subscribe((response) => {
      console.log('response');
      console.log(response)
      this.userfirstName = response.firstName;
      this.userId = response.userId;

    });

    this.user_profile = this.userService.getUserAbstractDetail().user_profile; //Get user_profile from user service getUserAbstractDetail function
    const request = {
      'userId': this.user_profile.user_id,
      'docId': this.doc_id
    }

    this.updateBtnstatus(request);
    this.documeService.getresponseForAllDocument(request).subscribe(
      (response) => {
        if (response['status']) {
          if (response['getDocObject'] != null) {
            const slide_innner_controls = response['getDocObject']['selected_doc_1'];
            var commonArray = ["Document should be uploaded in PDF, JPG, JPEG, PNG format only", "Any other format will not get uploaded", "Maximum document size should not exceed 5 MB"];
            var ul = document.getElementById("add_to_me");
            for (let j = 0; j < slide_innner_controls.length; j++) {
              const getInnerSlideElement = slide_innner_controls[j];
              // console.log(getInnerSlideElement.docUploadStatus);
              // console.log(getInnerSlideElement.tooltip);
              var docuMentArr = getInnerSlideElement.tooltip.split("---");
              // console.log(docuMentArr)



              docuMentArr.forEach(singleToolTip => {
                // document.getElementById("add_to_me").innerHTML+= '<li>'+singleToolTip+'</li>';
                var li = document.createElement('li');
                li.appendChild(document.createTextNode(singleToolTip));
                li.style.background = 'url("../../../assets/images/right-sign.png") center left no-repeat';
                li.style.backgroundPosition = "0px 7px";
                li.style.padding = "0px 0px 0px 27px";
                li.style.backgroundSize = "21px";
                li.style.lineHeight = "23px";
                li.classList.add("myLi");
                ul.appendChild(li);
              });

              //  document.getElementById("add_to_me").innerHTML+= '<li>'+getInnerSlideElement.tooltip+'</li>';
              this.docUploadWizard.push(getInnerSlideElement);
            }
            commonArray.forEach(commonElement => {
              var li = document.createElement('li');
              li.style.background = 'url("../../../assets/images/right-sign.png") center left no-repeat';
              li.style.backgroundPosition = "0px 7px";
              li.style.padding = "0px 0px 0px 27px";
              li.style.backgroundSize = "21px";
              li.style.lineHeight = "23px";
              li.classList.add("myLi");
              li.appendChild(document.createTextNode(commonElement));
              ul.appendChild(li);
            })
            //console.log(this.docUploadWizard)
            this.desableSpinner = false;
            this.documeService.sendMessage(this.desableSpinner);
          }
        }

      }
    );


  }



  updateBtnstatus(request) {

    this.numbersubscription = interval(1000)
      .subscribe((res) => {
        this.documeService.getresponseForAllDocument(request)
          .subscribe(
            (response) => {
              if (response['status']) {
                if (response['getDocObject'] != null) {
                  const slide_innner_controls = response['getDocObject']['selected_doc_1'];
                  this.docUploadStatusArray = [];
                  for (let j = 0; j < slide_innner_controls.length; j++) {
                    const getInnerSlideElement = slide_innner_controls[j];
                    this.docUploadStatusArray.push(getInnerSlideElement.docUploadStatus);

                  }
                }

                let result = this.docUploadStatusArray.every(function (e) {
                  return e == true;
                });
                this.allDocUploadStatus = result;
                console.log('result');
                console.log(this.allDocUploadStatus);


              }
            });
        console.log(this.docUploadStatusArray);
        if (this.allDocUploadStatus) {
          this.numbersubscription.unsubscribe();
        }

      });


  }

  dynamicDocumentUpload(index, event, formId, doc_id, doc_name) {
    console.log("aaaaa");
    this['uploadDocumentProgress_' + index] = 10;
    //get files from input field
    let fileList: FileList = event.target.files;  
    if (fileList.length > 0) {
      let file: File = fileList[0];
         if (file.size >= 5242880) {       
        UIkit.notification({
          message: 'Maximum file size supported 5MB only',
          status: 'danger',
          pos: 'bottom-center',
          timeout: 3000
        });
        return false;       
      }
      const document_download = event.target.files[0].type;
      const fileExtForFront = document_download.split('/')[1].toLowerCase();
      if (this.documentExtensions.indexOf(fileExtForFront) !== -1) {
        this['progressBarDisplay_' + index] = true;
        var i = 10;
        var intervalUpload = setInterval(() => {
          i = i + 10;
          if (i <= 100) {
            this['uploadDocumentProgress_' + index] = i;
            //  console.log(this.uploadDocumentProgress)
          } else {
            clearInterval(intervalUpload);
            this['progressBarDisplay_' + index] = false;
            this['selectedIndex' + index] = true;
            var formData = new FormData();
            const file = event.target.files[0];

            this.file = event.target.files[0];
            this.doc_name = doc_name;
            this.form_id = formId;
            this.selectedIndex = index;

            formData.append('file', file);
            formData.append('doc_id', doc_id);
            formData.append('file_name', doc_name);
            formData.append('form_id', formId);
            formData.append('pl_code', this.user_profile.loan_code);
            formData.append('user_id', this.user_profile.user_id.toString());
            formData.append('loan_id', this.user_profile.loan_id.toString());
            formData.append('pdf_password', null);

            //Service call to upload documents
            this.documeService.uploadDocumentToServer(formData).subscribe(
              (data) => {

                if (data != undefined) {
                  if (data['status'] == true) {
                    this['selectedIndex' + index] = true;
                    this['pdfPasswordDisplay_' + index] = false;
                    this.docUploadStatusText = true;
                    //this.docUploadText = "If you have upload all document in slide you are being automatically redirect to document list page..";

                    // UIkit.notification({
                    //   message: 'Your ' + doc_name + ' has been uploaded.',
                    //   status: 'success',
                    //   pos: 'bottom-center',
                    //   timeout: 3000
                    // });


                  } else {
                    this['selectedIndex' + index] = false;

                    UIkit.notification({
                      message: 'Your ' + doc_name + 'not uploaded.',
                      status: 'danger',
                      pos: 'bottom-center',
                      timeout: 3000
                    });
                  }

                } else {
                  this['selectedIndex' + index] = false;

                  UIkit.notification({
                    message: 'Your ' + doc_name + 'not uploaded.',
                    status: 'danger',
                    pos: 'bottom-center',
                    timeout: 3000
                  });
                }


              }, (err) => {
                console.log(err);
                if (err['error']['code'] == 400 && err['error']['message'] == "PDF_PASSWORD_PROTECTED_MSG") {
                  this['selectedIndex' + index] = false;
                  this['pdfPasswordDisplay_' + index] = true;


                  UIkit.notification({
                    message: 'pdf is passwrod Protected .',
                    status: 'danger',
                    pos: 'bottom-center',
                    timeout: 3000
                  });
                } else if (err['error']['code'] == 200 && err['error']['message'] == 'Successfully.') {
                  this['selectedIndex' + index] = true;
                  this['pdfPasswordDisplay_' + index] = false;

                  UIkit.notification({
                    message: 'Your ' + doc_name + ' has been uploaded.',
                    status: 'success',
                    pos: 'bottom-center',
                    timeout: 3000
                  });
                  this.router.navigate(['/document-list']);

                }
              });


          }
        }, 1000);
      } else {

        UIkit.notification({
          message: 'File should be PNG,JPEG,PDF format only.',
          status: 'danger',
          pos: 'bottom-center',
          timeout: 3000
        });
      }
    }
  }

  pdfPasswordSubmit() {
    if (this.pdf_password.nativeElement.value != null || this.pdf_password.nativeElement.value != undefined) {
      var formData = new FormData();
      const file = this.file;
      const pdf_password = this.pdf_password.nativeElement.value;
      const index = this.selectedIndex;

      formData.append('file', file);
      formData.append('doc_id', this.doc_id);
      formData.append('file_name', this.doc_name);
      formData.append('form_id', this.form_id);
      formData.append('pl_code', this.user_profile.loan_code);
      formData.append('user_id', this.user_profile.user_id.toString());
      formData.append('loan_id', this.user_profile.loan_id.toString());
      formData.append('pdf_password', pdf_password);
     

      this.documeService.uploadDocumentToServer(formData).subscribe(
        (data) => {          
          console.log("DOCUMENT UPLAOD WITH PASSWORD");
          console.log(data);
          if (data != undefined) {
            if (data['status'] == true) {
              this['selectedIndex' + index] = true;
              this['pdfPasswordDisplay_' + index] = false;

              UIkit.notification({
                message: 'Your ' + this.doc_name + ' has been uploaded.',
                status: 'success',
                pos: 'bottom-center',
                timeout: 3000
              });
            } else {
              this['selectedIndex' + index] = false;

              UIkit.notification({
                message: 'Your ' + this.doc_name + 'not uploaded.',
                status: 'danger',
                pos: 'bottom-center',
                timeout: 3000
              });
            }

          } else {
            this['selectedIndex' + index] = false;

            UIkit.notification({
              message: 'Your ' + this.doc_name + 'not uploaded.',
              status: 'danger',
              pos: 'bottom-center',
              timeout: 3000
            });
          }


        }, (err) => {
          console.log(err);
          if (err['error']['code'] == 400 && err['error']['message'] == "PDF_PASSWORD_WRONG_MSG") {
            this['selectedIndex' + index] = false;
            this['pdfPasswordDisplay_' + index] = true;

            UIkit.notification({
              message: 'Your pdf password is wrong .',
              status: 'danger',
              pos: 'bottom-center',
              timeout: 3000
            });
          }
        });
    } else {
      UIkit.notification({
        message: 'Enter pdf password',
        status: 'danger',
        pos: 'bottom-center',
        timeout: 3000
      });
    }


  }


  redirectToUploadDocument() {
    this.docUploadStatusText = true;
    //this.router.navigate(['/upload-document']);
  }
  openModal() {
    // if (this.docUploadStatusText == false) {
    //   UIkit.notification({
    //     message: 'Please Upload Document first.',
    //     status: 'danger',
    //     pos: 'bottom-center',
    //     timeout: 3000
    //   });
    //   return false;
    // }

    this.docUploadText = "Uploading Documents, please wait";
    this.isOpenedCheck = true;

    setTimeout(() => {
      this.docUploadText = "Checking File type, File size";
    }, 3000);

    setTimeout(() => {
      if (this.allDocUploadStatus == true) {
        this.docUploadStatusText = true;
        this.docUploadText = "Redirecting Please wait";
        setTimeout(() => {
          // this.router.navigate(['/upload-document']);
          this.updateAppStageAndRedirect(this.userId);
        }, 3000);
      } else {
        console.log("show other message");
        this.docUploadText = "Your status has not updated yet OR please upload all document."
        setTimeout(() => {
          this.isOpenedCheck = false;
        }, 4000);

      }
    }, 10000);
  }


  updateAppStageAndRedirect(userId: number) {
    this.documeService.checkAllDocumentUploadStatus(userId).subscribe((response) => {
      console.log("response from app stage");
      console.log(response)
      if (response['status']) {
        this.router.navigate(['/document-response']);
      } else {
        this.router.navigate(['/upload-document']);
      }

    }, (err) => {
      console.log(err);
    })
  }

  reset() {
    console.log(this.myInputVariable.nativeElement.files);
    this.myInputVariable.nativeElement.value = "";
    console.log(this.myInputVariable.nativeElement.files);
  }
}
